
import { PropType } from "vue"
export interface MenuItem {
  name: string
  icon?: string
  hint?: string
  hide?: boolean
  href?: string
  action?: () => void
  menu?: MenuItem[]
  reload?: boolean
  external?: boolean
  [key: string]: any
}

export default {
  name: "Menu",
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<MenuItem>,
      default: () => null,
    },
    openIcon: {
      type: String,
      default: null,
    },
    closeIcon: {
      type: String,
      default: null,
    },

    openBtnClasses: {
      type: String,
      default: null,
    },
    openBtnText: {
      type: String,
      default: null,
    },
    openButtonSize: {
      type: String as PropType<"sm" | "md" | "lg">,
      default: "lg",
    },
    menuItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => [],
    },
    menuPosition: {
      type: String,
      default: "bottom",
    },
    subMenuPosition: {
      type: String,
      default: "left",
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    openOnHover: {
      type: Boolean,
      default: false,
    },
    isOpenButtonOutlined: {
      type: Boolean,
      default: false,
    },
    openButtonColor: { type: String, default: "primary" },
    isOffsetX: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: null,
    },
    isOffsetY: { type: Boolean, default: true },
    isSubMenu: { type: Boolean, default: false },
    transition: { type: String, default: "slide-y-transition" },
  },
  data() {
    return { openMenu: false, isOpenOnHover: true, menuOpened: false }
  },
  computed: {
    activatorIcon() {
      if (this.openIcon && this.closeIcon) {
        return this.menuOpened ? this.closeIcon : this.openIcon
      } else {
        return this.openIcon || this.closeIcon
      }
    },
    isMenuPositionLeft() {
      return this.menuPosition === "left"
    },
    isMenuPositionRight() {
      return this.menuPosition === "right"
    },
    isMenuPositionTop() {
      return this.menuPosition === "top"
    },
    isMenuPositionBottom() {
      return this.menuPosition === "bottom"
    },
    buttonSize() {
      const sizes = {
        sm: "w-auto",
        md: "w-25",
        lg: "w-100",
      }

      return (sizes[this.openButtonSize] || "w-auto") as string
    },
  },
  watch: {
    menuOpened: function () {
      this.isOpenOnHover = !this.menuOpened
    },
  },
  methods: {
    emitClickEvent(item) {
      if (item.external) {
        return
      }

      if (item.reload) {
        this.$root.$emit("reset-search-params")
      }
      if (item.href) {
        this.$root.$emit("sidebar-navigation", item.href)
      }
      this.$emit("sub-menu-click", item)
      this.openMenu = !this.openMenu
      this.menuOpened = !this.menuOpened
    },
  },
}
