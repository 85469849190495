
import CamerasAutocomplete from "@/components/CamerasAutocomplete"
import * as tus from "tus-js-client/dist/tus.min.js"
import { Camera, ClipsCategory, MediaType } from "@evercam/shared/types"
import { MediaCreateRequestPayload } from "@evercam/shared/types/media"
import Vue from "vue"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { useCameraStore } from "@/stores/camera"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"
import { useProjectStore } from "@/stores/project"
import Textarea from "@/components/Textarea.vue"
import Menu from "@evercam/shared/components/Menu"

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3

export default Vue.extend({
  name: "MediaCreationDialog",
  components: {
    Menu,
    CamerasAutocomplete,
    Textarea,
  },
  data() {
    const { isAdmin, isImpersonationLogin } = useAccountStore()

    return {
      fileCount: "0",
      dialog: false,
      loading: false,
      camera: null,
      mediaType: "" as MediaType,
      title: "",
      fileExtension: null,
      fileUrl: null,
      url: null,
      currentStatus: 0,
      selectedFileName: "",
      file: {} as File,
      notes: "",
      category: ClipsCategory.HealthSafetySecurity,
      isUrgent: false,
      fileMenuItems: [
        {
          name: this.$t("content.media_hub.media_type.url"),
          icon: "fas fa-link",
          type: MediaType.ExternalUrl,
          hint: this.$t("content.media_hub.hints.default"),
          action: () => {
            this.mediaType = MediaType.ExternalUrl
            this.dialog = !this.dialog
          },
        },
        {
          name: this.$t("content.media_hub.media_type.file"),
          icon: "fas fa-upload",
          type: MediaType.File,
          hint: this.$t("content.media_hub.hints.default"),
          action: () => {
            this.mediaType = MediaType.File
            this.dialog = !this.dialog
          },
        },
        {
          name: this.$t("title.upload_local_clip"),
          icon: "fas fa-upload",
          type: MediaType.LocalClip,
          hint: this.$t("content.archives.hints.default"),
          hide: !isAdmin && !isImpersonationLogin,
          action: () => {
            this.dialog = !this.dialog
            this.mediaType = MediaType.LocalClip
          },
        },
      ],
    }
  },
  computed: {
    ...mapStores(
      useMediaHubStore,
      useAccountStore,
      useCameraStore,
      useLayoutStore,
      useProjectStore
    ),
    isInitial(): boolean {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving(): boolean {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess(): boolean {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed(): boolean {
      return this.currentStatus === STATUS_FAILED
    },
    isFileMediaType(): boolean {
      return this.mediaType === MediaType.File
    },
    isExternalUrlMediaType(): boolean {
      return this.mediaType === MediaType.ExternalUrl
    },
    isLocalClip(): boolean {
      return this.mediaType === MediaType.LocalClip
    },
    clipCategories(): { text: string; value: ClipsCategory }[] {
      return [
        {
          text: "Health & Safety, Security",
          value: ClipsCategory.HealthSafetySecurity,
        },
        { text: "Legal", value: ClipsCategory.Legal },
        { text: "Marketing", value: ClipsCategory.Marketing },
        { text: "Best Practices", value: ClipsCategory.BestPracticies },
      ]
    },
    fileValidation(): string {
      return `required${this.isLocalClip ? "|mp4_file" : ""}`
    },
  },
  watch: {
    dialog(visible) {
      if (this.isExternalUrlMediaType) {
        this.$analytics.saveEvent(
          AnalyticsEvent.MediaHubToggleAddMediaUrlDialog,
          { visible }
        )
      } else if (this.isFileMediaType) {
        this.$analytics.saveEvent(
          AnalyticsEvent.MediaHubToggleUploadFileDialog,
          { visible }
        )
      }
    },
  },
  methods: {
    onCancel() {
      this.dialog = false
      this.camera = null
      this.title = ""
      this.fileExtension = null
      this.fileUrl = null
      this.url = null
      this.currentStatus = 0
      this.selectedFileName = ""
      this.file = {} as File
      this.notes = ""
      this.category = ClipsCategory.HealthSafetySecurity
      this.isUrgent = false
    },
    async uploadFile() {
      this.loading = true

      return new Promise((resolve) => {
        const upload = new tus.Upload(this.file, {
          endpoint: `${this.$config.public.tusURL}`,
          resume: true,
          chunkSize: Infinity,
          retryDelays: [0, 1000, 3000, 5000],
          metadata: {
            filename: this.title,
            filetype: this.file.type,
          },
          uploadSize: this.file.size,
          onProgress: (bytesUploaded, bytesTotal) => {
            this.fileCount = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            this.currentStatus = STATUS_SAVING
          },
          onSuccess: () => {
            this.fileUrl = upload.url
            this.selectedFileName = ""
            this.fileExtension = upload.file.name.split(".").at(-1)
            this.currentStatus = STATUS_SUCCESS
            this.saveToMediaHub()
            this.loading = false
          },
          onError: (error) => {
            console.error("Failed because: " + error)
            this.loading = false
            this.currentStatus = STATUS_FAILED
          },
        })
        upload.start()
        resolve(null)
      })
    },
    saveToMediaHub() {
      this.loading = true
      let date = new Date()
      let payload: MediaCreateRequestPayload = {
        fromDate: date,
        toDate: date,
        title: this.title,
        type: this.mediaType,
        cameraExid: this.camera?.exid,
      }
      if (this.isFileMediaType) {
        this.$analytics.saveEvent(AnalyticsEvent.MediaHubUploadFile)
        payload = {
          ...payload,
          fileUrl: this.fileUrl,
          fileExtension: this.fileExtension,
        }
      } else if (this.isExternalUrlMediaType) {
        this.$analytics.saveEvent(AnalyticsEvent.MediaHubSaveMediaUrl)
        payload = { ...payload, url: this.url }
      } else if (this.isLocalClip) {
        payload = {
          ...payload,
          fromDate: this.$moment().subtract(1, "hour").toISOString(),
          toDate: this.$moment().subtract(30, "minutes").toISOString(),
          notes: this.notes,
          type: MediaType.File,
          storeAsType: MediaType.LocalClip,
          fileUrl: this.fileUrl,
          fileExtension: this.fileExtension,
          category: this.category,
          isUrgent: this.isUrgent,
        }
      }
      EvercamApi.mediaHub
        .cCreate(this.projectStore.selectedProjectExid, payload)
        .then(() => {
          this.$notifications.success("Clip created successfully")
          this.mediaHubStore.forceReloadMediaHub()
          this.$emit("media-created")
          this.camera = null
          this.title = ""
        })
        .catch((error) => {
          if (error?.response?.status !== 401) {
            this.$notifications.error({
              text: "Failed to create clip!",
              error,
            })
          }
        })
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    },
    onFileSelection(e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }
      // @ts-expect-error: Property 'validate' does not exist on type 'Element'.
      this.$refs.provider.validate(file.type).then((res) => {
        if (res.valid) {
          const inputElement = document.getElementById(
            "file-upload"
          ) as HTMLInputElement
          this.file = inputElement?.files?.[0]
          this.selectedFileName = e.target.files[0].name
        } else {
          return
        }
      })
    },
    filterCameras(cameras: Camera[]): Camera[] {
      return (cameras?.filter(
        (camera) => camera.project.id === this.projectStore.selectedProjectExid
      ) || []) as Camera[]
    },
  },
})
